<template>
  <div>
    <div class="header" style="padding:10px 0;margin:0;">
      <h4>代理的企业</h4>
    </div>
    <ListFilter :total="total" @close="putAway" style="padding:5px 0">
      <div style="display: flex;align-items: center;">
        <p>关键字：</p>
        <el-input
          prefix-icon="el-icon-search"
          style="margin-right: 10px; width: 200px"
          v-model="formInline.keywords"
          size="mini"
          @input="loadData()"
        ></el-input>
      </div>
    </ListFilter>
    <el-table :data="list" style="width: 100%">
      <el-table-column label="客户名称&统一信用编码" align="center" width="200">
        <template slot-scope="scope">
          <div>
            <p
              @click="handleClick(scope.row)"
              style="color: #409eff; text-decoration: underline; cursor: pointer"
            >
              {{ scope.row.name }}
            </p>
            <p>{{ scope.row.idCardNumber }}</p>
          </div>
        </template>
      </el-table-column>

      <!-- <el-table-column label="统一信用编码" align="center" width="200">
        <template slot-scope="scope">
          {{ scope.row.idCardNumber }}
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="经营范围">
        <template slot-scope="scope">
          {{ scope.row.range }}
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="客户类型">
        <template slot-scope="scope">
          {{ scope.row.type }}
        </template>
      </el-table-column> -->

      <el-table-column label="客户地址" width="250" align="center">
        <template slot-scope="scope">
          {{ scope.row.address }}
        </template>
      </el-table-column>

      <!-- <el-table-column label="行业">
        <template slot-scope="scope">
          {{ scope.row.industry }}
        </template>
      </el-table-column> -->

      <el-table-column label="联系人" align="center">
        <template slot-scope="scope">
          {{ scope.row.contacts }}
        </template>
      </el-table-column>

      <el-table-column label="联系人电话" align="center">
        <template slot-scope="scope">
          {{ scope.row.contactsPhone }}
        </template>
      </el-table-column>

      <!-- <el-table-column label="邮箱" align="center" width="180">
        <template slot-scope="scope">
          {{ scope.row.contactsMail }}
        </template>
      </el-table-column> -->

      <el-table-column label="费率" align="center">
        <template slot-scope="scope">
          {{ scope.row.rate }}
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          {{ scope.row.updateState }}
        </template>
      </el-table-column>

      <!-- <el-table-column label="所属企业">
        <template slot-scope="scope">
          {{ scope.row.agentEnterprise }}
        </template>
      </el-table-column> -->
      <el-table-column label="跟进分组" align="center">
        <template slot-scope="scope">
          {{ scope.row.agentGroupName }}
        </template>
      </el-table-column>
      <el-table-column label="跟进企业" width="250" align="center">
        <template slot-scope="scope">
          {{ scope.row.agentEnterpriseName }}
        </template>
      </el-table-column>

      <!-- <el-table-column label="所属个人">
        <template slot-scope="scope">
          {{ scope.row.agentUser }}
        </template>
      </el-table-column> -->

      <el-table-column label="跟进个人" align="center">
        <template slot-scope="scope">
          {{ scope.row.agentUserName }}
        </template>
      </el-table-column>

      <el-table-column label="创建时间" width="180" align="center">
        <template slot-scope="scope">
          {{ scope.row.createTime }}
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 12px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-size="pageSize"
        layout="prev,pager, next,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
//接口：
import { api } from '/src/api/base';
//查询列表接口
export const agentEnterpriseList = api()('agent.enterprise.list.all.json');

export default {
  components: {
    ListFilter: () => import('/src/components/filter')
  },
  data() {
    return {
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      formInline: {
        keywords: ''
      },
      list: []
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.loadData();
    },
    putAway() {
      this.filterOpen = false;
      this.pageNumber = 1;
      this.loadData();
    },
    loadData() {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline
      };
      agentEnterpriseList(params).then(res => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    goBack() {
      window.history.go(-1);
    },
    handleClick(row) {
      console.log(row.code);
      this.$router.push({
        path: '/system/enterprise/detail',
        query: {
          code: row.code
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
